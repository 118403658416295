import axios from "../../plugins/axios";
import router from "../../router";

const API_URL = 'https://timesheet.e-gits.com/accounts/api/v0/';
const GRIDFS_URL = 'https://timesheet.e-gits.com/gridfs/api/v0/';

const state = {
  token: localStorage.getItem("token") || "",
  user: {},
  userImage: "",
  users: [],
  status: "",
  error: null,
  alert: {
    active: false,
    text: "",
    type: "",
  },
  dates: [
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
    new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
  ],
};

const getters = {
  isLoggedIn: (state) => !!state.token,
  authState: (state) => state.status,
  user: (state) => state.user,
  userImage: (state) => state.userImage,
  error: (state) => state.error,
  alert: (state) => state.alert,
  users: (state) => state.users,
};

const actions = {
  createAlert({ commit }, alert) {
    commit("alert_request", alert);
  },

  // Login Action
  async login({ commit, dispatch }, user) {
    commit("auth_request");
    try {
      let res = await axios.post(API_URL + "authenticate", user);
      if (res.status === 200) {
        const token = res.data.jwtToken;
        const userId = res.data.id;
        // Store the token into the localstorage
        localStorage.setItem("token", token);
        // Set the axios defaults
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        commit("auth_success", { token, userId });
        dispatch("getProfile");
        dispatch("getProfileImage");
      }
      return res.status === 200;
    } catch (err) {
      commit("auth_error", err);
    }
  },

  // Get the user Profile
  async getProfileImage({ commit, getters }) {
    commit("profile_request");
    if (getters.isLoggedIn) {
      axios
        .get(GRIDFS_URL + "images/image")
        .then((response) => {
          commit("user_image", response.data.url);
        })
        .catch((error) => {
          console.log("Using default picture ", error);
          commit("user_image", require("@/assets/user_default.png"));
        });
    }
  },

  // Register User
  async register({ commit }, userData) {
    try {
      commit("register_request");
      let res = await axios.post(API_URL + "register", userData);
      if (res.data.success !== undefined) {
        commit("register_success");
      }
      return res;
    } catch (err) {
      commit("register_error", err);
    }
  },

  // Edit User
  async edit({ commit }, userData) {
    try {
      commit("register_request");
      let res = await axios.post(API_URL + "edit", userData);
      if (res.status === 200) {
        commit("register_success");
      }
      return res;
    } catch (err) {
      commit("register_error", err);
    }
  },

  // Create User
  async create({ commit }, userData) {
    try {
      commit("register_request");
      let res = await axios.post(API_URL, userData);
      if (res.status === 200) {
        commit("register_success");
      }
      return res;
    } catch (err) {
      commit("register_error", err);
    }
  },

  async updatePassword({ commit }, passwordData) {
    try {
      commit("register_request");
      let res = await axios.post(API_URL + "updatePassword", passwordData);
      if (res.status === 200) {
        commit("register_success");
      }
      return res;
    } catch (err) {
      commit("register_error", err);
    }
  },

  // Enable User
  async enableUser({ commit }, userData) {
    try {
      commit("profile_request");
      let res = await axios.post(API_URL + "enableAccount", userData);
      return res;
    } catch (err) {
      commit("register_error", err);
    }
  },

  // Get the user Profile
  async getProfile({ dispatch, commit, getters }) {
    if (getters.isLoggedIn) {
      commit("profile_request");
      let res = await axios.get(API_URL + localStorage.getItem("id"));
      commit("user_profile", res.data);
      dispatch("getAllUsers");
      return res;
    }
  },

  // Logout the user
  async logout({ commit }) {
    axios.post(API_URL + "revoke-token");
    commit("logout");
    router.push("/login");
    return;
  },

  // Logout the user on refresh token expiry
  async autologout({ commit }) {
    commit("logout");
    router.push("/login");
    return;
  },

  // Logout the user
  async resetError({ commit }) {
    commit("reset_error");
    return;
  },

  async getAllUsers({ commit }) {
    let res = await axios.get(API_URL);
    commit("all_users", res.data);
    return;
  },

  async getRoles() {
    return axios.get(API_URL + "roles");
  },

  async delete({ commit }, payload) {
    try {
      let res = await axios.delete(API_URL + "/" + payload);
      return res;
    } catch (err) {
      commit("request_error", err);
    }
  },

  async forgotpassword({ commit }, payload) {
    try {
      let res = await axios.post(API_URL + "/forgot-password", payload);
      return res;
    } catch (err) {
      commit("request_error", err);
    }
  },

  async resetpassword({ commit }, payload) {
    try {
      let res = await axios.post(API_URL + "/reset-password", payload);
      return res;
    } catch (err) {
      commit("request_error", err);
    }
  },

  updateDates({ commit }, payload) {
    commit("dates", payload);
  },
};

const mutations = {
  auth_request(state) {
    state.error = null;
    state.status = "loading";
  },
  auth_success(state, { token, userId }) {
    state.token = token;
    state.status = "success";
    localStorage.setItem("token", token);
    localStorage.setItem("id", userId);
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    state.error = null;
  },
  auth_error(state, err) {
    state.error = err.response.data.message;
  },
  alert_request(state, alert) {
    state.alert.active = true;
    state.alert.text = alert.text;
    state.alert.type = alert.type;
  },
  register_request(state) {
    state.error = null;
    state.status = "loading";
  },
  register_success(state) {
    state.error = null;
    state.status = "success";
  },
  register_error(state, err) {
    state.error = err.response.data.message;
    state.alert.active = true;
    state.alert.text = err.response.data.message;
    state.alert.type = "error";
  },
  logout(state) {
    state.error = null;
    state.status = "";
    state.token = "";
    state.user = "";
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    delete axios.defaults.headers.common["Authorization"];
  },
  profile_request(state) {
    state.status = "loading";
  },
  user_profile(state, user) {
    state.user = user;
  },
  all_users(state, users) {
    state.users = users;
  },
  user_image(state, image) {
    state.userImage = image;
  },
  request_error(state, err) {
    state.error = err.response.data.message;
  },
  reset_error(state) {
    state.error = null;
  },
  dates(state, dates) {
    state.dates = dates;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
