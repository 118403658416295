import axios from "axios";
import store from "../store";

const API_URL = 'https://timesheet.e-gits.com/accounts/api/v0/';

const apiAxios = axios.create({
  withCredentials: true,
  //baseURL: 'http://192.168.172.213:3001'
});

apiAxios.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Logout user if token refresh didn't work or user is disabled
    if (
      error.response &&
      error.response.data.message === "invalid refresh token"
    ) {
      store.dispatch("autologout");

      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response && error.response.data.message === "jwt expired") {
      console.log("request again with new token");
      //Try request again with new token
      return apiAxios
        .post(API_URL + "refresh-token")
        .then((res) => {
          const token = res.data.jwtToken;
          const user = res.data.name;
          store.commit("auth_success", { token, user });
          // New request with new token
          const config = error.config;
          config.headers["Authorization"] = `Bearer ${token}`;

          return new Promise((resolve, reject) => {
            apiAxios
              .request(config)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });
        })
        .catch((error) => {
          console.log("error ", error);
          Promise.reject(error);
        });
    }

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
);

export default apiAxios;
