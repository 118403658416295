import Vue from 'vue'
import App from '@/App.vue'
import axios from '@/plugins/axios';
import vuetify from '@/plugins/vuetify';
import router from '@/router'
import store from '@/store'
import '@/registerServiceWorker'
import  "@/helpers/filters";

Vue.config.productionTip = false

// Setting up default vue's http modules for api calls
Vue.prototype.$http = axios;
// Load the token from the localStorage
const token = localStorage.getItem("token");
// Is there is any token then we will simply append default axios authorization headers
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
