<template>
  <div id="app">
    <v-app>
      <customNavBar
        ref="navbar"
        v-if="isLoggedIn"
      />
      <v-main class="mx-2 my-2 pb-5">
        <router-view></router-view>
        <v-footer app class="py-0">
          <span class="ml-auto">SECO Mind Germany GmbH &copy;2020-2022</span>
        </v-footer>
      </v-main>
      <div class="text-center">
        <v-snackbar v-model="alert.active" color="transparent" elevation="0">
          <v-alert outlined :type="alert.type" text>
            {{ alert.text }}
          </v-alert>
        </v-snackbar>
      </div>
    </v-app>
  </div>
</template>

<script>
import CustomNavBar from "@/views/Navbar.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    CustomNavBar,
  },
  data: () => ({
    sidebarMenu: true,
    clipped: false,
    items: [
      {
        title: "Time Entries",
        href: "/",
        icon: "mdi-view-dashboard-outline",
      },
    ],
  }),
  computed: {
    ...mapGetters(["isLoggedIn","alert"]),
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>

<style>
.theme--dark.v-application {
  background-color: #181818;
}

#app {
  background: #f1f1f1;
}

.theme--dark.v-card,
.theme--dark.v-data-table,
.theme--dark.v-tabs-items,
.theme--dark.v-tabs .v-tabs-bar {
  background-color: #232323;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
