<template>
  <v-navigation-drawer :mini-variant="mini" v-model="showNavbar" permanent app>
    <v-list @click.stop="toggleMini = !toggleMini">
      <v-list-item>
        <v-list-item-content>
          <v-card elevation="5" rounded="l">
            <v-img
              class="ma-2"
              contain
              aspect-ratio="2"
              src="https://north.seco.com/fileadmin/user_upload/images/seco-logo.svg"
              v-if="!mini"
            ></v-img>
            <v-btn v-if="mini" icon @click.stop="toggleMini = !toggleMini">
              <v-icon class="mr-2" color="primary">mdi-chevron-right</v-icon>
            </v-btn>
          </v-card>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mx-2" color="white"></v-divider>
      <v-list-item class="px-2">
        <v-list-item-avatar class="mr-2">
          <v-avatar size="40" @click.stop="toggleMini = !toggleMini">
            <img :src="userImage" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title white--text">{{
            user.name
          }}</v-list-item-title>
          <v-list-item-subtitle class="mt-1 white--text">{{
            user.email
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-btn icon @click.stop="toggleMini = !toggleMini">
          <v-icon color="white">mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
    <v-divider class="mx-2" color="white"></v-divider>
    <v-list>
      <div v-for="menu in menuItems" :key="menu.title" style="overflow: auto">
        <v-list-item
          v-if="!menu.submenu && menu.role.includes(role)"
          link
          :to="menu.href"
          color="white"
        >
          <v-list-item-icon>
            <v-icon color="white">{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              menu.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group v-if="menu.submenu && menu.role.includes(role)" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{
                menu.title
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            class="pl-10"
            v-for="submenu in menu.submenu"
            :key="submenu.title"
            :to="submenu.href"
          >
            <v-list-item-title class="white--text">{{
              submenu.title
            }}</v-list-item-title>

            <v-list-item-icon>
              <v-icon color="white">{{ submenu.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </div>
      <v-list-item class="mt-2">
        <v-btn v-if="!mini" outlined @click="logout" color="white" block
          >LOG OUT</v-btn
        >
        <v-btn v-if="mini" icon @click="logout">
          <v-icon class="mr-3" color="white">mdi-logout-variant</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    showNavbar: true,
    toggleMini: false,
    menuItems: [
      {
        title: "Timesheet",
        href: "/",
        icon: "mdi-calendar-cursor",
        role: ["Admin", "User"],
      },
      {
        title: "Dashboard",
        href: "/dashboard",
        icon: "mdi-view-dashboard",
        role: ["Admin"],
      },
      {
        title: "All Users",
        href: "/allusers",
        icon: "mdi-application-cog",
        role: ["Admin"],
      },
      {
        title: "My Profile",
        href: "/profile",
        icon: "mdi-account",
        role: ["Admin", "User"],
      },
      {
        title: "Projects",
        href: "/projects",
        icon: "mdi-clipboard-text",
        role: ["Admin"],
      },
    ],
  }),
  mounted() {
    this.getProfile();
    this.getProfileImage();
  },
  methods: {
    ...mapActions(["logout", "getProfile", "getProfileImage"]),
  },
  computed: {
    ...mapGetters(["user", "users", "isLoggedIn", "userImage"]),
    mini() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.toggleMini
      );
    },
    role() {
      return this.$store.getters.user.role;
    },
  },
};
</script>

<style scoped>
.theme--light.v-navigation-drawer {
  background-color: #007bff;
}
</style>f